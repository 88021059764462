import { HelpOutline } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const ScheduleHelp = () => {
  const academyLink = process.env.REACT_APP_ACADEMYLINK;

  return (
    <IconButton
      onClick={() => window.open(academyLink, "_blank", "noopener,noreferrer")}
      sx={(theme) => ({
        color: "white",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      })}
    >
      <HelpOutline />
    </IconButton>
  );
};

export default ScheduleHelp;
