import TundraAppBar from "../../components/TundraAppBar/TundraAppBar";
import React from "react";
import Toolbar from "../../components/Toolbar/Toolbar";
import ChangesTable from "./ChangesTable";
import ExcelExport from "../../components/ExcelExport/ExcelExport";
import useTundraStore from "../../store/useTundraStore";
import constructParametersForChangesRequest from "../../utils/constructParametersForChangesRequest";
import { ChannelType } from "../../types/ChannelType";
import Container from "../../components/Container/Container";
import ChangesSubscriptionsModal from "./ChangesSubscriptionsModal/ChangesSubscriptionsModal";
import TypeOfChangePicker from "../../components/TypeOfChangePicker/TypeOfChangePicker";
import BroadcastTypesPicker from "../../components/BroadcastTypePicker/BroadcastTypesPicker";
import SearchInput from "../../components/SearchInput/SearchInput";
import ConnectedRegularChannelsPicker from "../../components/RegularChannelsPicker/ConnectedRegularChannelsPicker";
import ConnectedDateRangePicker from "../../components/DateRangePicker/ConnectedDateRangePicker";
import ChangeHelp from "./ChangeHelp";


const CHANGES_EXPORT_API_URL = process.env
  .REACT_APP_CHANGES_EXPORT_API as string;

const Changes = () => {
  const pickerStartDate = useTundraStore((state) => state.pickerStartDate);
  const pickerEndDate = useTundraStore((state) => state.pickerEndDate);
  const uiChannels = useTundraStore((state) => state.uiChannels);
  const isAuthenticated = useTundraStore((state) => state.isAuthenticated);
  const searchString = useTundraStore((state) => state.searchString);
  const setSearchString = useTundraStore((state) => state.setSearchString);

  const [broadcastTypes, setBroadcastTypes] = React.useState<number[]>([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 11,
  ]);

  const [typesOfChanges, setTypesOfChanges] = React.useState<number[]>([
    1, 2, 3, 4, 5,
  ]);

  const requestParametersForChangesRequest =
    constructParametersForChangesRequest(
      pickerStartDate,
      pickerEndDate,
      uiChannels
        .filter((channel) => channel.type === ChannelType.Channel)
        .map(({ id }) => id),
      typesOfChanges,
      broadcastTypes
    );

  return (
    <>
      <TundraAppBar pageName="Changes" innerComponent={<ChangeHelp />} />
      <Container>
        <Toolbar>
          <SearchInput value={searchString} onChange={setSearchString} />
          <ConnectedDateRangePicker />
          <ConnectedRegularChannelsPicker />
          <TypeOfChangePicker
            value={typesOfChanges}
            onChange={setTypesOfChanges}
          />
          <BroadcastTypesPicker
            value={broadcastTypes}
            onChange={setBroadcastTypes}
          />
          {isAuthenticated && <ChangesSubscriptionsModal />}
          <ExcelExport
            url={CHANGES_EXPORT_API_URL}
            data={requestParametersForChangesRequest}
          />
        </Toolbar>
        <ChangesTable
          typesOfChanges={typesOfChanges}
          broadcastTypes={broadcastTypes}
        />
      </Container>
    </>
  );
};

export default Changes;
