import { ProgramsOfChannel } from "./ProgramsOfChannel";
import { UIChannel } from "../types/UIChannel";
import { ChannelType } from "../types/ChannelType";
import { Program } from "../types/Program";
import isScheduleProgram from "./isScheduleProgram";

const findChannelForEmission = (program: Program, channels: UIChannel[]) => {
  const byChannelId = channels.find(
    (channel) =>
      channel.type === ChannelType.Channel && channel.id === program.channelId
  );
  if (byChannelId) return byChannelId;

  if (isScheduleProgram(program)) {
    const byAffiliate = channels.find(
      (channel) =>
        channel.type === ChannelType.Affiliate &&
        channel.id === program.affiliateId &&
        channel.slotId === program.slotId
    );
    if (byAffiliate) return byAffiliate;
  }
};

// splitProgramsByChannelOrSlot
// support splitting by slotIds as well
const splitProgramsByChannel = (
  programs: Program[],
  uiChannels: UIChannel[]
): ProgramsOfChannel[] => {
  const programsByChannel: Map<UIChannel, Program[]> = new Map();

  for (let program of programs) {
    const channelForProgram = findChannelForEmission(program, uiChannels);
    if (channelForProgram) {
      const programsForChannel = programsByChannel.get(channelForProgram) || [];
      programsForChannel.push(program);
      programsByChannel.set(channelForProgram, programsForChannel);
    }
  }

  return uiChannels.map((channel) => ({
    channel,
    programs: programsByChannel.get(channel) || [],
  }))
  .filter((entry) => entry.programs.length > 0);
};


export default splitProgramsByChannel;
